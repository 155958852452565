<template>
    <v-layout row>
        <v-flex xs12 sm6 offset-sm3>
            <v-list>
                <template v-for="(section, index) in settings">
                    <v-subheader :key=index>{{ $t(section.header) }}</v-subheader>
                    <template v-for="(item, itemIndex) in section.items">
                        <v-list-tile :key="item.key" @click="item.click">
                            <v-list-tile-avatar class="avatar">
                                <v-icon :color="item.highlight ? 'red' : 'info'">{{ item.icon }}</v-icon>
                            </v-list-tile-avatar>
                            <v-list-tile-content :class="item.highlight ? 'red--text' : ''"> {{ $t(item.value) }} </v-list-tile-content>
                            <v-list-tile-action>
                                <v-icon size=20 v-if="item.isVerified" color="success">check_circle_outline</v-icon>
                                <v-icon size=20 v-else-if="'isVerified' in item" color="warning">error_outline</v-icon>
                            </v-list-tile-action>
                        </v-list-tile>
                        <v-divider :key="item.key + itemIndex"></v-divider>
                    </template>
                </template>
            </v-list>
            <message-dialog
                :show.sync="msgDialog.show"
                :msg="msgDialog.msg"
                :primary-btn="msgDialog.primaryBtn"
                :secondary-btn="msgDialog.secondaryBtn"
                @primary="closeMsgDialogFunc"
            ></message-dialog>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import MessageDialog from '@/components/MessageDialog.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import UserMixin from '@/mixins/UserMixin'
import { HostUrl, SideBtnType } from '@/store'
import { UserStatus } from '@/mixins/UserMixin'
import { getAxiosConfig } from '@/utils/AuthService'
import { HOMETYPES } from '@/utils/utils'
import i18n from '@/i18n'

export default {
    name: 'Setting',
    components: { MessageDialog, WaitingDialog },
    mixins: [ MessageDialogControl, WaitDialogControl, UserMixin ],
    data() {
        return {
            profile: [
                { key: 'name', click: this.editName, icon: 'person' },
                { key: 'phone', click: this.editPhone, icon: 'smartphone' },
                { key: 'email', click: this.editEmail, icon: 'email' },
                { key: 'password', value: 'settings.profile.change-password', click: this.editPassword, icon: 'lock' }
            ],
            closeMsgDialogFunc: () => {},
        }
    },
    computed: {
        invoiceVehicle() {
            var target = ((store.getters.user || {}).invoice || {}).target || 'member'
            if (target == 'mobile') return i18n.t('updateInvoice.mobile-vehicle')
            else if (target == 'certificate') return i18n.t('updateInvoice.citizen-vehicle')
            else if (target == 'easycard') return i18n.t('updateInvoice.easycard-vehicle')
            else if (target == 'ipass') return i18n.t('updateInvoice.ipass-vehicle')
            else //if (target == 'member')
                return i18n.t('updateInvoice.member-vehicle')
        },
        settings() {
            const pageHome = this.$store.getters.pageHome;
            const user = store.getters.user
            var _filter
            if(user.isStaff == false && user.isLogistics == true) {
                if (pageHome == HOMETYPES.LOCKER) {
                    if (user.isStoreHost || user.isWashClerk) _filter = 3
                    else _filter = 1
                } else if (pageHome == HOMETYPES.STORE) {
                    if (user.isStoreHost) _filter = 1
                    else _filter = 3
                } else if (pageHome == HOMETYPES.WASH) {
                    if (user.isWashClerk) _filter = 1
                    else _filter = 3
                } else {
                    _filter = 3
                }
            } else if (pageHome == HOMETYPES.STORE && user.isStoreHost) _filter = 1
            else if (pageHome == HOMETYPES.WASH && user.isWashClerk) _filter = 1
            else _filter = 3

            if (_filter === 1) {
                return [
                    {
                        header: 'settings.other-settings',
                        items: [
                            { key: 'logout', value: 'settings.items.log-out', click: this.confirmLogout, icon: 'exit_to_app', highlight: true }
                        ]
                    }
                ]
            } else {
                return [
                    {
                        header: 'settings.personal-information',
                        items: this.updatedProfile
                    },
                    {
                        header: 'settings.other-settings',
                        items: [
                            { key: 'invoice', value: this.invoiceVehicle, click: this.editInvoice, icon: 'receipt' },
                            { key: 'sendInvoice', value: 'settings.send-invoice-email', click: this.sendInvoice, icon: 'forward_to_inbox' },
                            { key: 'logout', value: 'settings.items.log-out', click: this.confirmLogout, icon: 'exit_to_app', highlight: true }
                        ]
                    }
                ]
            }
        },
        updatedProfile() {
            var user = store.getters.user
            this.profile.forEach(field => {
                if (field.key in user) {
                    field.value = user[field.key] ||  `(${i18n.t('settings.not-set')})`
                    switch (field.key) {    // special cases
                        case 'phone':
                            field.isVerified = this.checkUserStatus(UserStatus.PhoneVerified)
                            break
                        case 'email':
                            field.isVerified = this.checkUserStatus(UserStatus.EmailVerified)
                            break
                        default:
                            break
                    }
                }
            })
            return this.profile
        }
    },
    methods: {
        editName() {
            this.$router.push(`/${i18n.locale}/changename/`)
        },
        editPhone() {
            this.$router.push(`/${i18n.locale}/changephone/`)
        },
        editEmail() {
            this.$router.push(`/${i18n.locale}/changeemail/`)
        },
        editPassword() {
            this.$router.push(`/${i18n.locale}/password/`)
        },
        confirmLogout() {
            this.closeMsgDialogFunc = this.logout
            this.showMsgDialog(`${i18n.t('settings.logout')}`, `${i18n.t('settings.yes-logout')}`, `${i18n.t('settings.cancel')}`)
        },
        editInvoice() {
            this.$router.push(`/${i18n.locale}/updateinvoice/`)
        },
        startBindEmail() {
            this.closeMsgDialog()
            this.$router.push(`/${i18n.locale}/changeemail/`)
        },
        sendInvoice() {
            let isBindEmail = false
            this.profile.forEach(field => {
                if (field.key === 'email') {
                    isBindEmail = field.isVerified
                }
            })
            
            if (isBindEmail === false) {
                this.closeMsgDialogFunc = this.startBindEmail
                this.showMsgDialog(i18n.t('settings.email-must-set'), i18n.t('settings.set-email'), i18n.t('settings.cancel'))
                return
            }

            this.showDialog(i18n.t('settings.sending-email'), i18n.t('settings.fail-send-email'))
            var vm = this
            axios.get(HostUrl + '/api/payment/invoice/mail', getAxiosConfig())
                .then(function (response) {
                    console.log('sendInvoice response', response)
                    if (response.data.code === 0) {
                        vm.closeMsgDialogFunc = () => {}
                        vm.showMsgDialog(i18n.t('settings.e-invoice-sent'), i18n.t('settings.ok'))
                    } else if (response.data.code === 510) {
                        vm.closeMsgDialogFunc = () => {}
                        vm.showMsgDialog(i18n.t('settings.no-invoice'), i18n.t('settings.ok'))
                    } else if (response.data.code == 511) {
                        vm.closeMsgDialogFunc = () => {}
                        vm.showMsgDialog(i18n.t('settings.system-busy'), i18n.t('settings.ok'))
                    } else {
                        vm.closeMsgDialogFunc = () => {}
                        vm.showMsgDialog(i18n.t('settings.fail-send-email-again'), i18n.t('settings.ok'))
                    }
                    vm.closeDialog()
                })
                .catch(function () {
                    vm.closeMsgDialogFunc = () => {}
                    vm.showMsgDialog(i18n.t('settings.fail-send-email-again'), i18n.t('settings.ok'))
                    vm.closeDialog()
                })
        },
        logout() {
            this.closeMsgDialog()
            store.dispatch('logout')
        }
    },
    activated() {
        store.commit('setTitle', 'settings.nav-title')
        store.commit('setSideBtnType', SideBtnType.Back)
    },
    deactivated() {
        store.commit('setSideBtnType', SideBtnType.Navi)
    }
}
</script>

<style scoped>
    .avatar {
        min-width: 50px;
    }
</style>
